import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoSetting } from "shared/model/edo-setting.model";
import { EdoSettingEnum } from "shared/model/enumerations/edo-setting.model";
import { AuthenticationState } from "shared/reducers/authentication";


interface StudentPopUpTimeoutProps {

}

const StudentPopUpTimeout = (props: StudentPopUpTimeoutProps) => {
    const TIMEOUT = 1000 * 10;
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const [open, setOpen] = useState<boolean>(false);
    const [timeoutValue, setTimeoutValue] = useState<number>(null);
    const [idleTime, setIdleTime] = useState<number>(0);
    const [countdown, setCountdown] = useState<number>(TIMEOUT / 1000);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

    const interval = useRef(null);
    const timeout = useRef(null);

    const handleGetTimeout = async () => {
        const requestUrl = `/settings/${EdoSettingEnum.STUDENT_POPUP_TIMEOUT}`;
        try {
            const response = await axios.get<{ data: IEdoSetting; }>(requestUrl);
            if (response.data?.data?.value && parseInt(response.data.data.value) > 0) {
                setTimeoutValue(parseInt(response.data.data.value, 10));
            }
        } catch (e) {
            setTimeoutValue(null);
        }
    };

    const handleKeepAlive = () => {
        setOpen(false);
        clearTimeout(timeout.current);
        clearInterval(interval.current);
        setCountdown(TIMEOUT / 1000);
        setIdleTime(0);
    };

    const handleLogout = () => {
        navigate("/logout");
    };

    const handleStartCountdown = () => {
        setOpen(true);
        interval.current = setInterval(() => {
            setCountdown(prevValue => prevValue - 1);
        }, 1000);
    };

    const handleStartTimeout = () => {
        timeout.current = setTimeout(() => {
            clearInterval(interval.current);
            handleStartCountdown();
        }, timeoutValue * 60 * 1000 - TIMEOUT);
    };

    const sendKeepAlive = () => {
        socket.emit('keep-alive');
    };

    useEffect(() => {
        if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT]) && timeoutValue !== null && idleTime === timeoutValue * 60 - TIMEOUT / 1000) {

            clearTimeout(timeout.current);
            clearInterval(interval.current);
            handleStartCountdown();
        }

    }, [account, timeoutValue, idleTime]);

    const handleKeepAliveEvent = () => {
        if (idleTime <= timeoutValue * 60 - TIMEOUT / 1000) {
            setIdleTime(0);
        }
    };

    useEffect(() => {
        if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT]) && timeoutValue !== null) {
            window.document.addEventListener('mousemove', handleKeepAliveEvent);
            window.document.addEventListener('keydown', handleKeepAliveEvent);
        }

        return () => {
            window.document.removeEventListener('mousemove', handleKeepAliveEvent);
            window.document.removeEventListener('keydown', handleKeepAliveEvent);
        };

    }, [account, timeoutValue]);

    useEffect(() => {
        handleGetTimeout();
        sendKeepAlive();
        setInterval(sendKeepAlive, 1000 * 60 * 5); // Send keep alive every 5 minutes
        setInterval(() => { setIdleTime(prevValue => prevValue + 1); }, 1000);

        return () => {
            clearInterval(interval.current);
            clearTimeout(timeout.current);
        };
    }, []);

    useEffect(() => {
        if (countdown <= 0) {
            clearInterval(interval.current);
            handleLogout();
        }
    }, [countdown]);

    return (
        <Dialog open={open}>
            <DialogTitle>
                Stai ancora lavorando?
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Se non hai ancora finito di lavorare, clicca su "Continua" per mantenere la sessione attiva. Altrimenti, clicca su "Esci" per terminare la sessione.
                    <br /><br />
                    {timeout && `La sessione scadrà tra ${countdown} secondi.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogout} color="secondary">
                    Esci
                </Button>
                <Button onClick={handleKeepAlive} color="primary">
                    Continua
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StudentPopUpTimeout;