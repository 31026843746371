import { Box, Typography } from "@mui/material";
import SetUserInfoDialog from "components/SetUserInfoDialog/SetUserInfoDialog";
import StudentPopUpTimeout from "components/StudentPopUpTimeout/StudentPopUpTimeout";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import CircularManagementRoutes from "pages/circulars-management";
import CourseQuizzesRoute from "pages/course-quizzes";
import DashboardRoutes from "pages/dashboard/dashboard";
import Home from "pages/home/home";
import ParentsRoutes from "pages/parents";
import QuizAttemptsRoute from "pages/quiz-attempts";
import QuizzesRoutes from "pages/quizzes";
import TeachersSupervisionRoutes from "pages/teachers-supervision";
import { useContext, useEffect } from "react";
import Loadable from "react-loadable";
import { Route, Routes } from "react-router-dom";
import PrivateRoute, { hasAnyAuthority } from "shared/auth/private-route";
import ErrorBoundary from "shared/error/error-boundary";
import PageNotFound from "shared/error/page-not-found";
import Header from "shared/layout/header/header";
import { AuthenticationState } from "shared/reducers/authentication";
import AmiciRoutes from "./pages/amici";
import ArchivioRoutes from "./pages/archivio";
import ChatRoutes from "./pages/chat/index";
import ClassesRoutes from "./pages/classes";
import InsegnamentoRoutes from "./pages/courses";
import VideostoreRoutes from "./pages/videostore";
import WikiRoutes from "./pages/wiki";

const loading = (
  <Box textAlign="center" p={2}>
    <Typography>Caricamento ...</Typography>
  </Box>
);

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ "pages/account"),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "administration" */ "pages/administration"),
  loading: () => loading,
});

const AppRoutes = () => {
  const socket = useContext(SocketContext);
  const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Header>
          <Routes>
            <Route
              path="admin/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ROLE_ADMIN]}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="circulars-management/*"
              element={
                <PrivateRoute
                  hasAnyAuthorities={[
                    AUTHORITIES.ROLE_ADMIN,
                    AUTHORITIES.ROLE_VICE,
                  ]}
                >
                  <CircularManagementRoutes />
                </PrivateRoute>
              }
            />
            <Route
              path="teachers-supervision/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ROLE_ADMIN]}>
                  <TeachersSupervisionRoutes />
                </PrivateRoute>
              }
            />
            <Route
              path="account/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_USER]}>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              path="classes/*"
              element={<ClassesRoutes />}
            />
            <Route
              path="repository/*"
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                ]}>
                  <ArchivioRoutes />
                </PrivateRoute>
              }
            />
            <Route
              path="quizzes/*"
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                ]}>
                  <QuizzesRoutes />
                </PrivateRoute>
              }
            />
            <Route
              path="course-quizzes/*"
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                  AUTHORITIES.ROLE_STUDENT
                ]}>
                  <CourseQuizzesRoute />
                </PrivateRoute>
              }
            />
            <Route
              path="quiz-attempts/*"
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                  AUTHORITIES.ROLE_STUDENT
                ]}>
                  <QuizAttemptsRoute />
                </PrivateRoute>
              }
            />
            <Route element={<PrivateRoute hasAnyAuthorities={[
              AUTHORITIES.ROLE_USER,
              AUTHORITIES.ROLE_STUDENT,
              AUTHORITIES.ROLE_TEACHER,
              AUTHORITIES.ROLE_VICE,
              AUTHORITIES.ROLE_ADMIN
            ]} />}>
              <Route
                path="videostore/*"
                element={<VideostoreRoutes />}
              />
              <Route
                path="wiki/*"
                element={<WikiRoutes />}
              />
              <Route
                path="friends/*"
                element={<AmiciRoutes />}
              />
              <Route
                path="chat/*"
                element={<ChatRoutes />}
              />
              <Route
                path="courses/*"
                element={<InsegnamentoRoutes />}
              />
              <Route
                path="feed/*"
                element={<Home />}
              />
            </Route>
            <Route
              path="parents/*"
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_PARENT,
                ]}>
                  <ParentsRoutes />
                </PrivateRoute>
              }
            />
            <Route
              index
              element={
                <PrivateRoute hasAnyAuthorities={[
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                  AUTHORITIES.ROLE_STUDENT,
                  AUTHORITIES.ROLE_SUPPORT_TEACHER,
                ]}>
                  <DashboardRoutes />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Header>
      </ErrorBoundary>
      {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT]) ? <StudentPopUpTimeout /> : null}
      <SetUserInfoDialog />
    </>
  );
};

export default AppRoutes;
